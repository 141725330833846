import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { PageIntro, NewsletterForm, FormSection } from '#sections'
import { Layout, SectionTitle, AuctionBlock } from '#components'

export default function PastAuctions({
  pageContext: { auctions, featuredImage, years },
}) {
  const [currYear, setCurrYear] = useState(years[0])

  return (
    <Layout title="A list of our past auctions">
      <PageIntro
        title="Previous Auctions"
        description="A list of our past auctions"
        preview={false}
        backgroundImage={featuredImage}
      />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-45 md:pt-55">
          <SectionTitle title="Previous Auctions" />
        </div>
        <div className="flex md:hidden items-center pt-40 overflow-x-scroll px-20 max-w-1360 mx-auto">
          {years.map((year) => (
            <button
              className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
                currYear === year ? `bg-red text-white` : `text-red bg-white`
              }`}
              type="button"
              key={year}
              onClick={() => setCurrYear(year)}
            >
              {year}
            </button>
          ))}
          <button
            className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
              currYear === `2013` ? `bg-red text-white` : `text-red bg-white`
            }`}
            type="button"
            onClick={() => setCurrYear(`2013`)}
          >
            2013
          </button>
          <button
            className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
              currYear === `2012` ? `bg-red text-white` : `text-red bg-white`
            }`}
            type="button"
            onClick={() => setCurrYear(`2012`)}
          >
            2012
          </button>
          <button
            className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
              currYear === `2011` ? `bg-red text-white` : `text-red bg-white`
            }`}
            type="button"
            onClick={() => setCurrYear(`2011`)}
          >
            2011
          </button>
        </div>
        <div className="hidden md:flex items-center pt-40 px-20 max-w-1360 mx-auto">
          {years.map((year) => (
            <button
              className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
                currYear === year ? `bg-red text-white` : `text-red bg-white`
              }`}
              type="button"
              key={year}
              onClick={() => setCurrYear(year)}
            >
              {year}
            </button>
          ))}
          <button
            className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
              currYear === `2013` ? `bg-red text-white` : `text-red bg-white`
            }`}
            type="button"
            onClick={() => setCurrYear(`2013`)}
          >
            2013
          </button>
          <button
            className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
              currYear === `2012` ? `bg-red text-white` : `text-red bg-white`
            }`}
            type="button"
            onClick={() => setCurrYear(`2012`)}
          >
            2012
          </button>
          <button
            className={`border-2 border-red block text-19 leading-none pt-11 pb-9 transition-all duration-300 px-15 mr-10 ${
              currYear === `2011` ? `bg-red text-white` : `text-red bg-white`
            }`}
            type="button"
            onClick={() => setCurrYear(`2011`)}
          >
            2011
          </button>
        </div>
        <div className="container">
          <div className="flex flex-wrap -mx-8 pt-28">
            {auctions &&
              auctions.filter(auction => auction.ID !== 2447 && auction.ID !== 2576 && auction.ID !== 2449 && auction.ID !== 2450 && auction.ID !== 2451 && auction.ID !== 2452).map(
                (auction, index) =>
                  auction.year === currYear && (
                    <div
                      className={`px-8 w-full mb-28 ${
                        index === 0 ? `` : `md:w-1/2 lg:w-1/3`
                      }`}
                      key={auction.AuctionDate}
                    >
                      <AuctionBlock auction={auction} featured={index === 0} />
                    </div>
                  )
              )}
            {currYear === `2013` ? (
              <div className="w-full px-8 mb-28">
                <p class="paItem">
                  11th December 2013{" "}
                  <span>
                    (100% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £7,056,850)
                  </span>
                </p>
                <p class="paItem">
                  30th October 2013{" "}
                  <span>
                    (93% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £5,505,750)
                  </span>
                </p>
                <p class="paItem">
                  11th September 2013{" "}
                  <span>
                    (91% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £9,015,686)
                  </span>
                </p>
                <p class="paItem">
                  25th July 2013{" "}
                  <span>
                    (88% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £9,620,250)
                  </span>
                </p>
                <p class="paItem">
                  5th June 2013{" "}
                  <span>
                    (86% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £5,901,750)
                  </span>
                </p>
                <p class="paItem">
                  17th April 2013{" "}
                  <span>
                    (88% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £7,643,100)
                  </span>
                </p>
                <p class="paItem">
                  27th February 2013{" "}
                  <span>
                    (83% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £9,448,000)
                  </span>
                </p>
              </div>
            ) : null}
            {currYear === `2012` ? (
              <div className="w-full px-8 mb-28">
                <p class="paItem">
                  12th December 2012{" "}
                  <span>
                    (90% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £5,780,500)
                  </span>
                </p>
                <p class="paItem">
                  31th October 2012{" "}
                  <span>
                    (89% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £10,880,900)
                  </span>
                </p>
                <p class="paItem">
                  12th September 2012{" "}
                  <span>
                    (83% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £4,559,250)
                  </span>
                </p>
                <p class="paItem">
                  19th July 2012{" "}
                  <span>
                    (88% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £7,761,750)
                  </span>
                </p>
                <p class="paItem">
                  30th May 2012{" "}
                  <span>
                    (85% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £4,253,500)
                  </span>
                </p>
                <p class="paItem">
                  12th April 2012{" "}
                  <span>
                    (90% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £4,861,000)
                  </span>
                </p>
                <p class="paItem">
                  22th February 2012{" "}
                  <span>
                    (96% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £3,971,250)
                  </span>
                </p>
              </div>
            ) : null}
            {currYear === `2011` ? (
              <div className="w-full px-8 mb-28">
                <p class="paItem">
                  14th December 2011{" "}
                  <span>
                    (85% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £2,032,000)
                  </span>
                </p>
                <p class="paItem">
                  1st November 2011{" "}
                  <span>
                    (73% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £3,135,850)
                  </span>
                </p>
                <p class="paItem">
                  6th September 2011{" "}
                  <span>
                    (96% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £3,428,000)
                  </span>
                </p>
                <p class="paItem">
                  13th July 2011{" "}
                  <span>
                    (74% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £4,812,500)
                  </span>
                </p>
                <p class="paItem">
                  19th May 2011{" "}
                  <span>
                    (82% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £4,525,600)
                  </span>
                </p>
                <p class="paItem">
                  8th March 2011{" "}
                  <span>
                    (90% Sale Rate &nbsp;&nbsp;|&nbsp;&nbsp; Total Raised:
                    £3,281,500)
                  </span>
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <NewsletterForm />
      <FormSection />
    </Layout>
  );
}

PastAuctions.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
